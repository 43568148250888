import React, { useState } from "react";
import { configs } from "../../api/config";
import "./AddAffiliateForm.css";
import { Typography } from "@mui/material";
import { StyledButton } from "../StyledComponents/StyledButton/StyledButton";
import { StyledTextField } from "../StyledComponents/StyledTextField/StyledTextField";

const AddAffiliateForm = () => {
  const [url, setUrl] = useState("");
  const [affiliatedUrl, setAffiliatedUrl] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const tag = configs.affliate_params;
    if (!url.includes("amazon")) {
      setError("URL must be an Amazon URL");
      setAffiliatedUrl("");
      return;
    }
    setError("");
    const baseUrl = url.split("?")[0];
    const newUrl = `${baseUrl}?tag=${tag}`;
    setAffiliatedUrl(newUrl);
  };

  return (
    <div
      className="centered-div"
      style={{ minHeight: `${window.innerHeight - 250}px` }}
    >
      <form onSubmit={handleSubmit}>
        <StyledTextField
          required
          margin="dense"
          fullWidth
          id="product_url"
          label="Enter the product URL:"
          name="product_url"
          size="small"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          InputLabelProps={{
            style: { fontSize: "1rem" },
          }}
          sx={{ width: "400px" }}
        />
        <StyledButton
          type="submit"
          id={"generate_url"}
          sx={{ mt: 1, mb: 1, ml: 2, width: "100px" }}
        >
          Submit
        </StyledButton>
      </form>
      {error && (
        <Typography
          color="error"
          variant="body2"
          sx={{ fontFamily: "Poppins Bold", fontSize: "14px", padding: 4 }}
        >
          {error}
        </Typography>
      )}
      {affiliatedUrl && (
        <div id="result">
          <Typography className="affiliated-url">
            Affiliated URL is: <br />
            <a
              id={`affiliated_url`}
              href={affiliatedUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {affiliatedUrl}
            </a>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AddAffiliateForm;
