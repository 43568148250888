import React, { useEffect } from "react";
import "./App.css";
import Header from "./component/header/Header";
import Footer from "./component/footer/Footer";
import { Grid, Stack } from "@mui/material";
import CustomScroll from "./component/common/CustomScrollButton/CustomScroll";
import HomePage from "./component/home/HomePage";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import EmbeddedHTMLContent from "./component/EmbeddedHTML/EmbeddedHTMLContent";
import Beta from "./component/common/Beta/Beta";
import AddAffiliateForm from "./component/AddAffilateForm/AddAffiliateForm";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { title, content } = location.state || {};
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/home");
    }
  }, [navigate, location]);

  useEffect(() => {}, [location, title, content]);

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.hash]);

  return (
    <div className="App">
      <Stack
        className="stack"
        px={2}
        bgcolor="common.white"
        height={window.innerHeight}
        borderRadius={"9px"}
      >
        <CustomScroll>
          <Grid container gap={1} flexDirection="column" paddingTop={1}>
            <Grid item xs={12} md={12} lg={12}>
              <Beta />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{ paddingTop: 3 }}>
              <Header />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Routes>
                <Route path="/home" element={<HomePage />} />
                <Route path="/home/:section" element={<HomePage />} />
                <Route path="/add-affiliate" element={<AddAffiliateForm />} />
                <Route
                  path="/blogs/:blogName"
                  element={
                    <EmbeddedHTMLContent title={title} content={content} />
                  }
                />
                <Route
                  path="/:slug"
                  element={
                    <EmbeddedHTMLContent title={title} content={content} />
                  }
                />
              </Routes>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Footer />
            </Grid>
          </Grid>
        </CustomScroll>
      </Stack>
    </div>
  );
}

export default App;
