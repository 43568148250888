import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "rgba(15, 14, 150, 1) !important",
  width: "400px",
  "& label": {
    color: "rgba(15, 14, 150, 1) !important",
    fontWeight: 400,
    fontSize: "1rem",
    paddingLeft: "5px",
    paddingRight: "5px",
    backgroundColor: "#fff",
  },
  "& input": {
    color: "rgba(15, 14, 150, 1)",
    fontFamily: "Poppins Regular",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: "1.192rem",
    borderColor: "transparent",
  },
  "& fieldset": {
    color: "rgba(15, 14, 150, 1) !important",
    padding: "10px 15px",
    //   transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      borderColor: "transparent",
      background: "transparent",
    },
    "&.Mui-focused": {
      borderColor: "transparent",
      background: "transparent",
    },
  },
  "& .MuiOutlinedInput-root": {
    // linear gradient rounder border
    background:
      "linear-gradient(white,white) padding-box,linear-gradient(180deg, #D3131D 0%, #160F92 100%) border-box",
    borderRadius: "0.5em",
    border: "0.25px solid transparent",
    color: "rgba(15, 14, 150, 1) !important",
    "& fieldset": {
      border: "none",
      background: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
      background: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      background: "transparent",
    },
    "&.MuiInputLabel-outlined": {
      transform: "translate(14px, 16px) scale(1)",
    },
    "& legend": {
      display: "none",
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
      {
        color: "red", // Change the color to your desired color
        '&[data-user-value="true"]': {
          color: "blue", // Change the color to your desired color
        },
      },
  },
  "& .MuiOutlinedInput-input:-webkit-autofill": {
    WebkitBoxShadow: "0 0 0 1000px white inset !important",
    color: "rgba(15, 14, 150, 1) !important",
  },
  "& .MuiFormHelperText-root": {
    fontFamily: "Poppins Regular",
    marginLeft: "4px",
    width: "max-content",
  },
  "& .MuiInputAdornment-root": {
    //  color: theme.palette.form.placeholder,
    fontFamily: "Poppins",
    fontStyle: "italic",
    fontSize: "10px",
    "& .MuiInputAdornment-positionEnd": {
      //  backgroundColor: "#f6f8f9",
    },
  },
}));
