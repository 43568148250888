import React from "react";
import "./Beta.css";
import { Typography } from "@mui/material";

const Beta = () => {
  //  return <div className="beta beta-2">PricePinger - Beta Website </div>;
  return (
    <Typography id="beta-section" align="center" className="beta">
      PricePinger - Beta Website
    </Typography>
  );
};

export default Beta;
