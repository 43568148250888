import axios, { AxiosInstance } from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

// Base64 encode the username and password
const username = process.env.REACT_APP_USERNAME || "";
const password = process.env.REACT_APP_PASSWORD || "";
const basicAuth = "Basic " + btoa(username + ":" + password);

// Creating a customized Axios instance with custom configurations
export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,

  headers: {
    Authorization: basicAuth,
    "Content-Type": "application/json",
  },
});
