import { Grid } from "@mui/material";
import React from "react";
import BannerCarousel from "../BannerCarousel/BannerCarousel";
import BlogCarousel from "../BlogCarousel/BlogCarousel";
import DealsCarousel from "../DealsCarousel/DealsCarousel";

const HomePage = () => {
  return (
    <div>
      <Grid
        container
        gap={3}
        display="flex"
        flexDirection="column"
        paddingTop={1}
      >
        <Grid item xs={12} md={12} lg={12}>
          <BannerCarousel />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <DealsCarousel />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <BlogCarousel />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </div>
  );
};

export default HomePage;
