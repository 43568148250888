export const configs = {
  top_deals_api_endpoint: "/products",
  banners_api_endpoint:
    "/banners?acf_format=standard&_fields=id,title.rendered,acf.banner_image,acf.banner_url,acf.banner_alt",
  blogs_api_endpoint: "/posts?_fields=id,title.rendered,link",
  blog_posts_api_endpoint: "/posts/",
  blog_content_api_endpoint: "?_fields=title,content",
  pages_api_endpoint: "/pages/",
  affliate_params: "pricepinger07-21",
};
