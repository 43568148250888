import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import pp_logo from "../../assets/pricePingerLogo.svg";
//import SearchByName from "../common/Search/Search";
import {
  Divider,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";

import "./Header.css";
import { Link } from "react-router-dom";

const pages = [
  { nav: "Top Deals", navLink: "top-deals-section" },
  { nav: "Blogs", navLink: "blog-section" },
];

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "12px",
    padding: "10px",
    boxShadow: "0 4px 10px 0 rgba(181, 220, 246, 0.31)",
    border: "2px solid inherit",

    backgroundColor: "#fff",
    fontFamily: "Poppins SemiBold",
    fontSize: "12px",
    fontStyle: "oblique",
    color: "rgba(35, 15, 139, 1)",
    "& .MuiTooltip-arrow": {
      color: "#f3f9fd",
    },
  },
}));

export default function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  /*   const handleSearch = (query: string) => {
    //setSearchQuery(query);
   // getSearchedList(); 
  
  }; */

  return (
    <AppBar
      position="static"
      id="header-toolbar"
      sx={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "none",
        fontFamily: "Poppins Regular",
      }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar disableGutters>
          <IconButton
            disableRipple
            aria-label="price-pinger-logo-label"
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              padding: { xs: "0px" },
            }}
            component={Link}
            to="/home"
          >
            <img
              id="price-pinger-logo"
              alt="Price Pinger Logo"
              src={pp_logo}
              className="responsive-logo"
            ></img>
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={`menu-item${page.nav}`}
                  onClick={handleCloseNavMenu}
                >
                  <Link
                    to={`/home#${page.navLink}`}
                    className="menu-link-styles"
                  >
                    <Typography textAlign="center" className="menu-item-styles">
                      {page.nav}
                    </Typography>
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            {pages.map((page, index) => (
              <React.Fragment key={page.nav}>
                <Button
                  key={`button${page.nav}`}
                  href={`#${page.navLink}`}
                  className="menu-item-button-styles"
                  sx={{
                    my: 2,
                    mr: index === pages.length - 1 ? 4 : 0, // Add margin right to the last item
                  }}
                  component={Link}
                  to={`/home#${page.navLink}`}
                >
                  {page.nav}
                </Button>
                {index < pages.length - 1 && (
                  <Divider
                    orientation="vertical"
                    sx={{
                      mx: 1,
                      height: "20px",
                      position: "relative",
                      top: "25px",
                    }}
                  />
                )}
              </React.Fragment>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <CustomTooltip title={"Coming soon"} arrow>
              <div>
                <Button
                  id="plugin_link"
                  variant="contained"
                  className="save_item"
                  sx={{
                    fontSize: { xs: "12px", sm: "16px", md: "18px" },

                    fontWeight: 700,

                    whiteSpace: "nowrap",

                    "&.Mui-disabled": {
                      color: "#fff",
                    },
                  }}
                  disabled
                >
                  Grab the plugin
                </Button>
              </div>
            </CustomTooltip>
            {/*  <SearchByName SearchText={"Search.."} handleSearch={handleSearch} /> */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
