import { CircularProgress, Container } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

interface EmbeddedHTMLContentProps {
  title?: string;
  content?: string;
}

const EmbeddedHTMLContent: React.FC<EmbeddedHTMLContentProps> = ({
  title,
  content,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = React.useState("0px");
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const state = useMemo(() => location.state || {}, [location.state]);

  const onLoad = () => {
    setHeight(
      iframeRef?.current?.contentWindow?.document.body.scrollHeight + "px"
    );
    setLoading(false);
  };

  useEffect(() => {
    const iframe = document.querySelector("iframe");
    const iframeCurrentRef = iframeRef.current;

    if (iframe && iframeCurrentRef) {
      const page_title = title || state?.title || "";
      const page_body = content || state?.content || "";
      const doc = iframe.contentWindow?.document;

      if (doc) {
        setHeight("0px");
        setLoading(true);

        doc.open();
        doc.write("<h3><b>" + page_title + "</b></h3>" + page_body);

        // Append stylesheet links to the iframe's document head
        const head = doc.querySelector("head");
        if (head) {
          const stylesheets = [
            "/Css-WP/wp-block-image-style.css",
            "/Css-WP/font-styles.css",
            "/Css-WP/wp-block-library.css",
            "/Css-WP/wp-block-navigation.css",
            "/Css-WP/inline-styles.css",
          ];
          stylesheets.forEach((stylesheet) => {
            const link = doc.createElement("link");
            link.rel = "stylesheet";
            link.href = stylesheet;
            head.appendChild(link);
          });
        }

        // Setting styles
        doc.body.style.overflowY = "auto";
        doc.body.style.textAlign = "justify";
        doc.body.style.backgroundColor = "transparent";
        doc.body.style.paddingLeft = "0";
        doc.body.style.paddingRight = "0";
        doc.body.className =
          "entry-content alignfull wp-block-post-content has-global-padding is-layout-constrained wp-block-post-content-is-layout-constrained";
        doc.close();
      }
    }
  }, [title, content, state]);

  return (
    <Container maxWidth={"xl"}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </div>
      )}
      <iframe
        title={`${title}`}
        ref={iframeRef}
        scrolling="no"
        onLoad={onLoad}
        height={height}
        style={{
          width: "100%",
          border: "none",
          overflow: "hidden",
          backgroundColor: "transparent",
          minHeight: `${window.innerHeight - 280}px`,
          paddingLeft: "0",
          paddingRight: "0",
        }}
        sandbox="allow-same-origin allow-scripts"
      />
      <br />
    </Container>
  );
};

export default EmbeddedHTMLContent;
