import React from "react";
import "./BlogCard.css";
import {
  Card,
  CardActionArea,
  Container,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { serviceFetchBlogsUrlPath } from "../../../api/service/services";

type Props = {
  id: string;
  cardBG: string;
  title: string;
  blogName: string;
  blogUrl: string;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: "12px",
    padding: "10px",
    boxShadow: "0 4px 10px 0 rgba(181, 220, 246, 0.31)",
    border: "2px solid inherit",

    backgroundColor: "#fff",
    fontFamily: "Poppins SemiBold",
    fontSize: "12px",
    fontStyle: "oblique",
    color: "rgba(35, 15, 139, 1)",
    "& .MuiTooltip-arrow": {
      color: "#f3f9fd",
    },
  },
}));

const BlogCard: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleCardClick = async () => {
    await serviceFetchBlogsUrlPath(props.id)
      .then((response: AxiosResponse) => {
        const blogData = response.data;
        navigate(`/blogs/${props.blogName}`, {
          state: {
            title: blogData.title.rendered,
            content: blogData.content.rendered,
          },
        });
      })
      .catch((error: AxiosError<any>) => {
        console.error("Error fetching blog data:", error);
      });
  };
  return (
    <Container maxWidth="xs">
      {/*  <Link
        to={`/blogs/${props.blogName}.html`}
        state={{ blogUrl: props?.blogUrl }}
        style={{ textDecoration: "none" }}
      > */}
      <Card
        id={`blog-${props?.id}`}
        variant="outlined"
        sx={{
          minWidth: "188px",
          maxWidth: "188px",
          maxHeight: "148px",
          minHeight: "148px",
          borderRadius: "10px",

          backgroundImage: `url(${props?.cardBG})`,

          // backgroundSize: "390px",
          backgroundRepeat: "round",
          width: "100%",
          height: "100%",
          border: 0,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255)",
          },
        }}
        onClick={handleCardClick}
      >
        <CardActionArea>
          <CustomTooltip title={props?.title} placement="top">
            <div className="blog-title-style">{props?.title}</div>
          </CustomTooltip>
        </CardActionArea>
      </Card>
      {/*      </Link> */}
    </Container>
  );
};

export default BlogCard;
