import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import next from "../../assets/nextIcon.svg";
import prev from "../../assets/previousIcon.svg";
import { Container, IconButton, Skeleton, Typography } from "@mui/material";
import "../BannerCarousel/BannerCarousel.css";
import DealsCard from "../common/DealsCard/DealsCard";
import { serviceFetchProductDetails } from "../../api/service/services";
import { AxiosResponse } from "axios";

const DealsCarousel: React.FC = () => {
  const [productData, setProductData] = useState<any>([]);
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    serviceFetchProductDetails().then((response: AxiosResponse) => {
      const data = response?.data;

      if (Array.isArray(data)) {
        setProductData(data);
      } else {
        setProductData([]);
      }
    });
  }, []);

  useEffect(() => {}, [productData]);

  const CustomPrevArrow: React.FC = (props) => (
    <div {...props} className="custom-prev-arrow">
      <IconButton
        disableRipple
        aria-label="previous slider"
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="previous-icon"
          alt="previous icon"
          src={prev}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const CustomNextArrow: React.FC = (props) => (
    <div {...props} className="custom-next-arrow">
      <IconButton
        disableRipple
        aria-label="next slider"
        sx={{
          "&:hover": { backgroundColor: "transparent" },
        }}
      >
        <img
          id="next-icon"
          alt="next icon"
          src={next}
          className="arrow-styles"
        />
      </IconButton>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    prevArrow: currentSlide === 0 ? <></> : <CustomPrevArrow />,
    nextArrow:
      currentSlide >= productData.length - 5 ? <></> : <CustomNextArrow />,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 1536, // xl
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1280, // lg
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 960, // md
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768, // new breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600, // sm
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480, // xs
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth={"xl"}
      /*  maxWidth={false}
      style={{ maxWidth: "100%" }} */
      className="container"
    >
      <Typography
        id="top-deals-section"
        align="left"
        className="top-deals-styles"
      >
        Top deals
      </Typography>
      {productData.length === 0 && (
        <Skeleton variant="rounded" height={300} animation="wave" />
      )}
      <Slider {...settings}>
        {productData?.map((deal: any, index: number) => (
          <div key={index} style={{ width: "auto" }}>
            <DealsCard
              // eslint-disable-next-line no-useless-escape
              imageUrl={deal.product_images.replace(/[\[\]']/g, "")}
              title={deal.post_title}
              price={deal.product_discounted_price}
              originalPrice={deal.product_original_price}
              retailerLogo={deal.retailer}
              discount={deal.product_discount_percentage}
              hoverText={"Save Upto"}
              hoverPercentage={deal.product_discount_percentage}
              id={deal.product_id}
              productLink={deal.product_url_retailer}
            />
          </div>
        ))}
      </Slider>
    </Container>
  );
};

export default DealsCarousel;
