import { ButtonBase, styled } from "@mui/material";

export const StyledButton = styled(ButtonBase)(() => ({
  opacity: 1,
  width: "100px",
  height: "36px",
  color: "#FFF",
  borderRadius: "0.25em",
  backgroundColor: "rgba(15, 14, 150, 1) !important",
  fontFamily: "Poppins",
  fontSize: "13px",
  fontWeight: 700,
  lineHeight: "27px",
  // padding: "23.98px 30px",
  textAlign: "center",
  alignItems: "center",
  marginTop: "10px",
  marginBottom: 0,
  /*  // linear gradient rounder border
  background:
    "linear-gradient(white, white) padding-box,linear-gradient(180deg, #D3131D 0%, #160F92 100%) border-box",
  borderRadius: "0.5em",
  border: "0.25px solid transparent", */
  textTransform: "capitalize",

  "&.Mui-disabled": {
    opacity: 0.497559,
  },
}));
